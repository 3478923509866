const fContent: {
    [name: string]: {
        img: string,
        link: string,
        hover: string,
        cont: number,
        pos: { x: number, y: number },
        anchor: number,
        hw?: number
        hwm: number
    }
} = {
    resume: {
        img: '/assets/icons/icon-resume.png',
        link: '/resume',
        hover: 'For my resume',
        cont: 0,
        pos: { x: 0, y: 0 },
        anchor: 0,
        hw: 100,
        hwm: 100,
    },
    discord: {
        img: '/assets/icons/icon-discord.png',
        link: '',
        hover: 'For my gamers',
        cont: 1,
        pos: { x: 15, y: 120 },
        anchor: 0,
        hw: 77,
        hwm: 10,
    },
    linked: {
        img: '/assets/icons/icon-in.png',
        link: 'https://www.linkedin.com/in/dreiparrent/',
        hover: 'For my career',
        cont: 2,
        pos: { x: 230, y: 40 },
        anchor: 0.5,
        hw: 60,
        hwm: 65,
    },
    twitter: {
        img: '/assets/icons/icon-twitter.png',
        link: 'https://twitter.com/decentDrei',
        hover: 'For my followers',
        cont: 2,
        pos: { x: 205, y: 115 },
        anchor: 0.5,
        hw: 64,
        hwm: 68,
    },
    git: {
        img: '/assets/icons/icon-git.png',
        link: 'https://https://github.com/Dreiparrent/',
        hover: 'For my nerds',
        cont: 2,
        pos: { x: 155, y: 180 },
        anchor: 0.5,
        hwm: 65,
    },
    email: {
        img: '/assets/icons/round_email_white.png',
        link: 'mailto:drei@aparrent.com',
        hover: 'For my contact',
        cont: 0,
        pos: { x: 105, y: 3 },
        anchor: 0.5,
        hwm: 80,
    }
};
export const fansContent = [
    fContent.resume,
    fContent.discord,
    fContent.linked,
    fContent.twitter,
    fContent.git,
    fContent.email
];