export interface IdContent {
    name: string;
    shortName: string;
    sub: string;
    code: string;
    completion: string;
    linkName: string;
    link: string;
    img: string;
    bg: string;
    bgm: string;
    colors: {
        primary: number;
        accent: number;
    }
}
const dContent: { [name: string]: IdContent } = {
    Blank: {
        name: '',
        shortName: '',
        sub: '',
        code: '',
        completion: '',
        linkName: '',
        link: '/',
        img: '',
        bgm: '',
        bg: '',
        colors: {
            primary: 0x000000,
            accent: 0x000000,
        }
    },
    OpenGarage: {
        name: 'Open Garage',
        shortName: 'Open Garage',
        sub: 'Open Garage is a social experiment to connect communities with the skills of neighbors and friends.',
        code: 'Angular',
        completion: 'nada',
        linkName: 'opengarage.aparrent.com',
        link: 'https://opengarage.aparrent.com',
        img: '/assets/img/og-logo.png',
        bg: '/assets/sites/og.png',
        bgm: '/assets/sites/og-mobile.png',
        colors: {
            primary: 0x91155e,
            accent: 0x292670,
        }
    },
    WMI: {
        name: 'Working Men\'s Institute',
        shortName: 'WMI',
        sub: 'The Working Men\'s Institute library and museum is Indiana\'s oldest public library. It functions as a invaluable service to citizens of New Harmony.',
        code: 'HTML/CSS/JS',
        completion: 'nada',
        linkName: 'wmi.aparrent.com',
        link: 'https://wmi.aparrent.com',
        img: '/assets/img/wmi-logo.png',
        bg: '/assets/sites/wmi.png',
        bgm: '/assets/sites/wmi-mobile.png',
        colors: {
            primary: 0xEFE4CF,
            accent: 0xC8504D,
        }
    },
    DD: {
        name: 'Dance Database',
        shortName: 'Dandce Database',
        sub: 'A startup to provide better access to dance and fitness classes. This is a unified schedule of all the programs nearby.',
        code: 'React',
        completion: 'nada',
        linkName: '',
        link: '/',
        img: '/assets/img/dd-logo.png',
        bg: '/assets/sites/dd.png',
        bgm: '/assets/sites/dd.png',
        colors: {
            primary: 0x9c27b0,
            accent: 0xE38C2D,
        }
    },
    Sourcerer: {
        name: 'Sourcerer',
        shortName: 'Sourcerer',
        sub: 'My personal concept of simple and easily digestable news in a format that accounts for multiple source perspectives',
        code: 'HTML/JS/CSS',
        completion: 'nada',
        linkName: '',
        link: '/',
        img: '/assets/img/sour-logo.png',
        bg: '/assets/sites/sourcerer.png',
        bgm: '/assets/sites/sourcerer.png',
        colors: {
            primary: 0x673ab7,
            accent: 0x14D84B,
        }
    },
    SS: {
        name: 'Savvy Savings',
        shortName: 'Savvy Savings',
        sub: 'A potential startup which finds deals of nearby retailers.',
        code: 'Angular',
        completion: 'nada',
        linkName: 'savvysavings.aparrent.com',
        link: 'https://savvysavings.aparrent.com',
        img: '/assets/img/ss-logo.png',
        bg: '/assets/sites/ss.png',
        bgm: '/assets/sites/ss-mobile.png',
        colors: {
            primary: 0x18C29C,
            accent: 0x126872,
        }
    },
    Kahani: {
        name: 'Kahani',
        shortName: 'Kahani',
        sub: 'Kahani: meaning story in Hindi. A one-of-a-kind concept for easily accessable storytelling and review.',
        code: 'Angular',
        completion: 'nada',
        linkName: 'kahani.tech',
        link: 'https://kahani.aparrent.com',
        img: '/assets/img/kahani-logo.png',
        bg: '/assets/sites/kahani.png',
        bgm: '/assets/sites/kahani-mobile.png',
        colors: {
            primary: 0x673ab7,
            accent: 0xe91e63,
        }
    }
};
export const designContents = [
    dContent.Kahani,
    dContent.OpenGarage,
    dContent.WMI,
    dContent.DD,
    dContent.SS,
    dContent.Sourcerer,
    dContent.Blank
];
export enum DContents {
    Kahani,
    OpenGarage,
    WMI,
    DD,
    SS,
    Sourcerer,
    Blank
}